import React, { useState, useMemo } from "react"
import { navigate } from "gatsby"
import { Collapse } from "react-collapse"
import client from "../../service/graphql"
import mutations from "../../service/mutations"

import Photo from "../../images/consultor.png"
import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Button,
  BackButton,
  Alert,
  AlertLabel,
  AlertValue,
  Info,
  InfoContent,
  InfoHeader,
  Icon, 
  Loading,
  ContainerError,
  ErrorText
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"
import { toReal } from "../../utils/currency"
import HeaderProgress from "../../components/HeaderProgress"

const Agree = ({ deal }) => {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const token = useMemo(() => (typeof window !== "undefined") ? localStorage.getItem('@verizaLP:token') : '', []);

  const handleAgree = async () => {
    setLoading(true);
    try {
      client.setHeader('authorization', `Bearer ${token}`)

      await client.request(mutations.CREATE_CODE_PARTICIPANT, {
        input: {
          code: deal?.identification,
        }, 
      }, {
        headers:{
          authorization: `Bearer ${token}`
        }
      });

      navigate("/success-invite")
    } catch (error) {
      setError(error.response.errors[0]
        ? error.response.errors[0].message
        : "ocorreu um erro inesperado");
    } finally{
      setLoading(false);
    }
  }

  const back = () => {
    navigate(-1);
  }

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={80} />
      <Container>
        <Section>
          <Body>
            <Header>
              <Profile>
                <User src={Photo} />
              </Profile>
              <Label><p>A solicitação Veriza é em grupo, os parceiros são responsáveis pelos créditos uns dos outros.</p></Label>
            </Header>
            <Form>
              <Alert>
                <AlertLabel>Valor de solicitação por parceiro:</AlertLabel>
                <AlertValue>{toReal(deal?.value)}</AlertValue>
              </Alert>
              <Info>
                <InfoHeader>
                  <p>Não entendi, me explique mais</p>
                  <button
                    className={expanded && 'expanded'}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <Icon />
                  </button>
                </InfoHeader>
                <Collapse isOpened={expanded}>
                  <InfoContent>
                    <p>Você e seus parceiros são corresponsáveis pelo empréstimo. Caso alguém não pague as parcelas em dia, as outras pessoas serão responsbilizadas pelo pagamento. Assim conseguimos um crédito melhor para você!</p>
                  </InfoContent>
                </Collapse>
              </Info>
              {error.length > 0 && (
                <ContainerError>
                  <ErrorText>{error}</ErrorText>
                </ContainerError>
              )}
              {loading && <Loading />}
            </Form>
          </Body>
          <Buttons>
            <BackButton onClick={back}>Voltar</BackButton>
            <Button disabled={loading} onClick={handleAgree}>
              Entendi e concordo
            </Button>
          </Buttons>
        </Section>
      </Container>
    </div>
  )
}

export default Agree
