import styled from 'styled-components';
import media from "styled-media-query"
import { Link } from "gatsby"

export const Container = styled.div`
  width: 100%;
  z-index: 100;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(19,84,52,0.13);
  z-index: 99;

  ${ media.greaterThan("medium")`
    justify-content: space-between;
  ` }
`

export const Logo = styled.img`
  margin-left: 25px;
  src: url(${props => props.src});
  width: 36px;
  height: 35px;

  ${media.greaterThan("medium")`
    margin-left: 76px;
  `}
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    margin-left: 16px;
  `}

  ${media.greaterThan("medium")`
    margin-right: 17%;
  `}
`

export const Cancel = styled(Link)`
  margin-right: 30px;

  ${media.lessThan("medium")`
    margin-left: auto;
  `}
`

export const Image = styled.img`
  width: 16px;
  height: 16px;
  opacity: 0.8;

  src: url(${props => props.src});
`

export const Name = styled.h1`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.45px;
  color: ${props => props.theme.colors.charcoal};
  opacity: 0.6;
`

export const Description = styled.p`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.67px;
  color: ${props => props.theme.colors.charcoal};
`

export const ProgressBar = styled.div`
  height: 8px;
  background-color: #e3f3e4;
`

export const Progress = styled.div`
  width: ${props => `${props.percent}%`};
  height: 8px;
  border-radius: 0px 100px 100px 0px;
  background-color: ${props => props.theme.colors.greenDark};
  transition: width 450ms ease-in-out;  
`
