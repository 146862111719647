import React from 'react';

import { Container, Cancel, Description, Header, Image, Logo, Name, Title, ProgressBar, Progress } from './styles'

import Veriza from "../../images/symbol.png"
import Close from "../../images/cancel.png"

function HeaderProgress({ percent, name, description, toCancel = '/' }) {
  return (
    <Container>
      <Header>
        <Logo src={Veriza} />
        <Title>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </Title>
        <Cancel to={toCancel}>
          <Image src={Close} />
        </Cancel>
      </Header>
      <ProgressBar>
        <Progress percent={percent} />
      </ProgressBar>
    </Container>
  )
}

export default HeaderProgress;
