import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import FinishInvite from "../sections/finishInvite"

function CodeInvite({ location }) {
  return (
    <Layout>
      <SEO title="Cadastro" />
      <FinishInvite deal={location.state?.deal}/>
    </Layout>
  );
}

export default CodeInvite;